.CreateProfileForm .form-control.Invalid {
    border-color: #D7373F;
}

.form-control.Invalid {
    border-color: #D7373F;
}


.error{
    font-size: 13px !important;
    color: #D7373F !important;
    text-align: left !important;
  
}